import React, { FC } from 'react';
import Button from 'common/Button';
import './BuyNowIcon.scss';
import GatsbyImage from 'common/GatsbyImage';
import { IBuyNowIconComponentProps } from './models';

const BuyNowIcon: FC<IBuyNowIconComponentProps> = ({
  imageAltLabel,
  image,
  buttonText,
  ariaLabel,
  buttonLink,
}) => {
  return (
    <div className="buy-now-icon">
      <GatsbyImage
        key={imageAltLabel}
        className="buy-now-icon__image"
        fluid={image?.childImageSharp.fluid}
        alt={imageAltLabel}
      />
      <Button className="buy-now-icon__button" link={buttonLink[0].url} ariaLabel={ariaLabel}>
        {buttonText}
      </Button>
    </div>
  );
};

export default BuyNowIcon;
