import { graphql } from 'gatsby';
import React, { FC } from 'react';
import classnames from 'classnames';

import { Container, DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';
import Seo from 'gatsby-theme-husky/src/common/Seo';
import HeroBanner from 'gatsby-theme-husky/src/components/HeroBanner';
import BuyNowRow from 'gatsby-theme-husky/src/components/BuyNowRow';
import PromoProductNavigation from 'gatsby-theme-husky/src/components/PromoProductNavigation';
import TopFooter from 'gatsby-theme-husky/src/components/Footer/TopFooter/TopFooter';
import BottomFooter from 'gatsby-theme-husky/src/components/Footer/BottomFooter/BottomFooter';

import { IPromoProductBuyNowPageComponentProps } from 'gatsby-theme-husky/src/templates/PromoProductBuyNowPage/models';
import 'gatsby-theme-husky/src/templates/PromoProductBuyNowPage/PromoProductBuyNowPage.scss';

const PromoProductBuyNowPage: FC<IPromoProductBuyNowPageComponentProps> = ({
  data: {
    umbracoPromoProductBuyNow: {
      seoMetaDescription,
      seoMetaKeywords,
      seoMetaTitle,
      seoExternalHreflangs,
      defaultCompositions,
      promoProductPageTitle,
      promoIconsButtonsSection,
      navigation,
      bottomMainHeroBanner: [
        {
          properties: { title, imageAlt, image, button },
        },
      ],
      pageTheme,
      disclaimer,
    },
    brandSettings,
  },
}) => {
  const { copyrightBlock, hideMediaLinks } = defaultCompositions.footer;

  return (
    <>
      <Seo
        siteSettings={defaultCompositions.siteSettings}
        brandSettings={brandSettings}
        {...{
          seoMetaDescription,
          seoMetaKeywords,
          seoMetaTitle,
          seoExternalHreflangs,
        }}
        lang={defaultCompositions.siteSettings.lang}
      />
      <div
        className={classnames('promo-product-buy-now', {
          [`promo-product-buy-now-${pageTheme}`]: pageTheme,
        })}
      >
        <Container fluid className="promo-product-buy-now__navigation">
          <Container>
            <PromoProductNavigation {...{ navigation }} />
          </Container>
        </Container>
        <h1 className="promo-product-buy-now__title">{promoProductPageTitle}</h1>
        <BuyNowRow dataBuyNowRow={promoIconsButtonsSection} />
        <DangerouslySetInnerHtml className="promo-product-buy-now__disclaimer" html={disclaimer} />
        <HeroBanner
          title={title}
          altImageText={imageAlt}
          image={image}
          button={button}
          inFirstViewport
          reversed
          className="promo-product-buy-now__bannerfooter"
          headerLevel={2}
        />
        <footer className="footer">
          <Container className="footer-container">
            <TopFooter
              dataLogo={brandSettings}
              dataMediaLinks={brandSettings.mediaLinks}
              dataFooterHideMediaLinks={hideMediaLinks}
            />
            <BottomFooter dataCopyright={copyrightBlock} />
          </Container>
        </footer>
      </div>
    </>
  );
};

export const query = graphql`
  query PromoProductBuyNowPageAU($url: String = "") {
    umbracoPromoProductBuyNow(url: { eq: $url }) {
      url
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      navigation {
        properties {
          image {
            childImageSharp {
              fluid(maxWidth: 195, quality: 100, sizes: "(max-width: 1199px) 108px, 195px") {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          altLabel
          link {
            url
          }
          buttonText
          buttonLink {
            url
          }
        }
      }
      bottomMainHeroBanner {
        properties {
          title
          imageAlt
          image {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          button {
            ...FragmentButton
          }
        }
      }
      promoIconsButtonsSection {
        ...FragmentpromoIconsButtonsSectionAU
      }
      promoProductPageTitle
      pageTheme
      disclaimer
    }
    brandSettings {
      brandName
      brandLogo {
        fallbackUrl
        svg {
          content
        }
      }
      brandLogoAlt
      brandLogoLinkAriaLabel
      brandName
      openGraphImage {
        url
      }
      youtubeUrl
      facebookUrl
      mediaLinks {
        properties {
          mediaAriaLabel
          altLabel
          image {
            fallbackUrl
            svg {
              content
            }
          }
          link {
            url
            name
          }
        }
      }
    }
  }
`;

export default PromoProductBuyNowPage;
