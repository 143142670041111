import React, { FC } from 'react';

import { IBuyNowRowComponentProps } from 'gatsby-theme-husky/src/components/BuyNowRow/models';
import { graphql } from 'gatsby';
import BuyNowIcon from 'gatsby-theme-husky/src/components/BuyNowRow/BuyNowIcon/BuyNowIcon';

import 'gatsby-theme-husky/src/components/BuyNowRow/BuyNowRow.scss';

const BuyNowRow: FC<IBuyNowRowComponentProps> = ({ dataBuyNowRow }) => {
  return (
    <div className="buy-now-row">
      {dataBuyNowRow?.map(
        ({ properties: { imageAltLabel, image, buttonText, ariaLabel, buttonLink } }) => (
          <BuyNowIcon {...{ imageAltLabel, image, buttonText, ariaLabel, buttonLink }} />
        )
      )}
    </div>
  );
};

export default BuyNowRow;

export const query = graphql`
  fragment FragmentpromoIconsButtonsSectionAU on PromoIconsButtonsSectionTypes {
    properties {
      ariaLabel
      buttonText
      imageAltLabel
      buttonLink {
        url
      }
      image {
        childImageSharp {
          fluid(
            maxWidth: 253
            quality: 100
            sizes: "(max-width: 575px) 253px, (max-width: 1199px) 314px, 271px"
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
