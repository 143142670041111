import React, { FC } from 'react';
import { Link } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';
import classNames from 'classnames';

import Button from 'common/Button';

import { IPromoProductNavigationComponentProps } from 'components/PromoProductNavigation/models';
import 'components/PromoProductNavigation/PromoProductNavigation.scss';

const PromoProductNavigation: FC<IPromoProductNavigationComponentProps> = ({
  navigation,
  productNameBtnText,
  discoverMoreBtnText,
  activeSlide,
  setActiveSlide,
}) => {
  const { image, altLabel, link } = navigation?.[0].properties;
  const { buttonText, buttonLink } = navigation?.[1].properties;

  return (
    <header className="promo-product-page-header">
      <div className="promo-product-page-header__logo">
        <Link to={link[0].url}>
          <GatsbyImage fluid={image?.childImageSharp.fluid} alt={altLabel} />
        </Link>
      </div>

      {discoverMoreBtnText && productNameBtnText && setActiveSlide ? (
        <div className="promo-product-page-header__views-navigation">
          <button
            className={classNames(`promo-product-page-header__views-navigation--left`, {
              [`active`]: activeSlide,
            })}
            type="button"
            onClick={() => setActiveSlide(true)}
          >
            {productNameBtnText}
          </button>
          <button
            className={classNames(`promo-product-page-header__views-navigation--right`, {
              [`active`]: !activeSlide,
            })}
            type="button"
            onClick={() => setActiveSlide(false)}
          >
            {discoverMoreBtnText}
          </button>
        </div>
      ) : null}

      <Button
        link={buttonLink[0].url}
        classes="promo-product-page-header__button"
        ariaLabel={buttonText}
      >
        {buttonText?.toUpperCase()}
      </Button>
    </header>
  );
};

export default PromoProductNavigation;
